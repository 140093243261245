.skill-icon {
    cursor: pointer;
    max-height: 93px;
    width: fit-content;
    height: fit-content;
    transition: all 0.4s cubic-bezier(.07,.71,.58,.94);
}

.skill-icon img {
    max-width: 93px;
    height: auto;
    width: auto;
}

.skill-icon:hover {
    transform: scale(1.05);
}