:root {
    --skills-heading-font: normal 400 40px/54px var(--heading-font-family);
    --skills-sheading-font: normal 600 22px/31px var(--body-font-family);
    --skills-vert-padding: 110px;
}

#skills-background {
    min-height: 923px;
    height: fit-content;
    pointer-events: all;
}

#skills-wrapper {
    padding-top: var(--skills-vert-padding);
    padding-bottom: var(--skills-vert-padding);
    height: auto;
}

#skills-heading {
    position: relative;
    font: var(--skills-heading-font);
    letter-spacing: 0.01em;
    color: var(--text-color);
    padding-left: 0;
    padding-right: 0;
}

#skills-heading::after {
    position: absolute;
    content: "";
    left: 0;
    bottom: -20.5px;
    border-top: 3px solid var(--line-color);
    width: 143.5px;
}

#row-skills-1 {
    margin-top: 74px;
}

#row-skills-2 {
    margin-top: 32px;
    gap: 32px;
}

#row-skills-2 div.col-lg-7,
#row-skills-2 div.col {
    padding: 0;
}

.skills-content {
    background: rgba(0, 0, 0, 0.4);
    border: 3px solid #FFFFFF;
    border-radius: 15px;
    padding-top: 32px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 32px;
}

#content-tech {
    width: 100%;
    min-height: 205px;
    height: auto;
}

#content-tools, #content-libraries {
    /* min-height: 336px; */
    height: fit-content;
}

.skills-sheading {
    font: var(--skills-sheading-font);
    text-transform: uppercase;
    letter-spacing: 0.03em;
    color: var(--text-color);
}

.skills-icon-row {
    margin-top: 22px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 30px;
    column-gap: 40px;
    justify-content: space-evenly;
    align-items: center;
}

#content-tools .skills-icon-row, 
#content-libraries .skills-icon-row {
    padding-left: 50px;
    padding-right: 50px;
}

/* Adjusting background margins for smaller screen sizes */
/* Scaled down by 0.9 */
@media screen and (max-width: 1280px) {   
    #skills-wrapper {
        padding-top: calc(var(--skills-vert-padding) * 0.5);
        padding-bottom: calc(var(--skills-vert-padding) * 0.5);
    }
}

@media screen and (max-width: 1150px) {
    #skills-wrapper {
        padding-top: calc(var(--skills-vert-padding) * 0.3);
        padding-bottom: calc(var(--skills-vert-padding) * 0.3);
    }
}

@media screen and (max-width: 992px) {
    #skills-wrapper {
        padding-top: calc(var(--skills-vert-padding) * 0.2);
        padding-bottom: calc(var(--skills-vert-padding) * 0.2);
    }
}

@media screen and (max-width: 600px) {
    #skills-wrapper {
        margin-top: calc(var(--skills-vert-padding) * -0.2);
        margin-bottom: calc(var(--skills-vert-padding) * -0.2);
        padding-top: 0;
        padding-bottom: 0;
    }
}

@media screen and (max-width: 350px) {
    #skills-wrapper {
        margin-top: calc(var(--skills-vert-padding) * -1);
        margin-bottom: calc(var(--skills-vert-padding) * -1);
    }
}