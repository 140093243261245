.btn-link {
    display: block;
    width: fit-content;
    height: fit-content;
    text-decoration: none;
}

.btn-wrapper {
    display: flex;
    width: fit-content;
    min-height: 56.7px;
    height: auto;
    gap: 11.7px;
    align-items: center;
    padding: 15.3px 25.2px 15.3px 22.5px;

    background: #68BC75;
    border: 2px solid #676E76;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    transition: all 0.2s ease-in-out
}

.btn-icon {
    width: 26.1px;
    height: 26.1px;
}

.btn-text {
    font: normal 600 17px/23px var(--body-font-family);
    letter-spacing: 0.02em;
    color: var(--text-color);
    vertical-align: middle;
}

.btn-wrapper:hover {
    background: #50ac5e; 
}

.btn-wrapper:hover .btn-icon, 
.btn-wrapper:focus .btn-icon {
    animation: bellshake .5s cubic-bezier(.36,.07,.19,.97) both;
    backface-visibility: hidden;
    transform-origin: top right;
}

@keyframes bellshake {
    0% { transform: rotate(0); }
    15% { transform: rotate(5deg); }
    30% { transform: rotate(-5deg); }
    45% { transform: rotate(4deg); }
    60% { transform: rotate(-4deg); }
    75% { transform: rotate(2deg); }
    85% { transform: rotate(-2deg); }
    92% { transform: rotate(1deg); }
    100% { transform: rotate(0); }
}

@media screen and (max-width: 991px) {
    .btn-text {
        font-weight: 500;
    }
}