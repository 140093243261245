#scrollspy-wrapper {
    width: 50px;
    height: 275px;
    position: absolute;
    margin-top: auto;
    margin-bottom: auto;
    top: 0;
    bottom: 0;
    right: 40px;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    /* background: white; */
    opacity: 1;
    visibility: visible;
    z-index: 20;
    transition: right 0.5s cubic-bezier(.07,.71,.58,.94), opacity 0.3s ease-in-out, visibility 0s;
    pointer-events: none;
}

#scrollspy-wrapper.hidden {
    right: 10px;
    opacity: 0;
    visibility: hidden;
    transition: right 0.5s cubic-bezier(.07,.71,.58,.94), opacity 0.3s ease-in-out, visibility 0s 0.3s;
}

.scrollspy-btn {
    position: relative;
    width: 16px;
    height: 16px;
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.7);
    cursor: pointer;
    transition: all 0.3s cubic-bezier(.07,.71,.58,.94), background-color 0.4s ease-in-out;
    pointer-events: all;
    overflow: visible;
    z-index: 22;
}

.scrollspy-btn span {
    transform: scale(0.7);
    position: absolute;
    font: normal 400 14px/30px var(--body-font-family);
    letter-spacing: 0.02em;
    color: white;
    background-color: rgb(26, 26, 26);
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 5px;
    text-transform: uppercase;
    text-align: center;
    vertical-align: middle;
    right: 0px;
    top: -8px;
    opacity: 0;
    user-select: none;
    visibility: visible;
    transition: right 0.3s cubic-bezier(.07,.71,.58,.94), transform 0.25s ease-in-out, 0.3s ease-in-out, visibility 0s 0.3s;
    pointer-events: none;
    z-index: 21;
}

.scrollspy-btn span::after {
    content: " ";
    position: absolute;
    top: 50%;
    left: 100%; 
    margin-top: -8px;
    border-width: 8px;
    border-style: solid;
    border-color: transparent transparent transparent rgb(26, 26, 26);
}

.scrollspy-btn:hover {
    transform: scale(1.2);
}

.scrollspy-btn:hover span {
    transform: scale(0.8);
    right: 25px;
    opacity: 1;
    visibility: visible;
    transition: right 0.3s cubic-bezier(.07,.71,.58,.94), transform 0.25s ease-in-out, opacity 0.3s ease-in-out, visibility 0s;
}

.scrollspy-btn.in-section {
    background-color: #64c274;
}

@media screen and (max-width: 991px) {
    #scrollspy-wrapper {
        opacity: 0;
        visibility: hidden;
        transition: right 0.3s ease-in-out, opacity 0.2s ease-in-out, visibility 0s 0.2s;
    }    
}