.social-btn {
    width: 42.5px;
    height: 42.5px;
    border-radius: 999px;
    overflow: hidden;
    align-items: center;
    transition: all 0.15s ease-in-out;
}

.social-img {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    transition: all 0.15s ease-in-out;
}

.social-btn:hover {
    border: 2.5px solid var(--line-color);
    transform: scale(1.1);
}