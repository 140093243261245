#footer-background {
    position: absolute;
    bottom: 0;
    display: flex;
    width: 100%;
    min-height: 90px;
    height: fit-content;
    background: black;
    align-items: center;
    justify-content: center;
    pointer-events: all;
}

#footer-wrapper {
    width: fit-content;
    height: fit-content;
    margin-top: 20px;
    margin-bottom: 20px;
    margin: 20px 40px;
    text-align: center;
}

#footer-credits {
    font: normal 200 18px/30px var(--body-font-family);
    color: var(--text-color);
}

@media screen and (max-width: 600px) {
    #footer-credits {
        font-size: 15px;
    }
}

@media screen and (max-width: 350px) {
    #footer-credits {
        font-size: 12px;
        line-height: 0.2px;
    }
}